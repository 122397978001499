import { useState } from "react"
import styled from "styled-components"

import { formatUTCSecondsDate } from "src/components/Account/BillingPortal/ChangePlan/utils"
import {
  getAlertType,
  isUnpaid,
  translateInvoiceStatus,
} from "src/components/Account/BillingPortal/Payments/paymentsUtil"
import { Table, Tbody, Thead } from "src/components/Account/BillingPortal/Table"
import { IInvoice } from "src/components/Account/types"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { formatPrice } from "src/utils/formatPrice"

import { NextRetry } from "./Payments"

export function DesktopTable({
  invoices,
  onPayInvoice,
  getPDF,
}: {
  invoices: IInvoice[]
  onPayInvoice: (id: string) => void
  getPDF: (id: string) => Promise<void>
}) {
  const { t } = useTranslate()
  const [downloadingID, setDownloadingID] = useState<string | null>()

  async function handleDownload(invoiceID: string) {
    setDownloadingID(invoiceID)
    await getPDF(invoiceID)
    setDownloadingID(null)
  }

  return (
    <Table>
      <Thead>
        <tr>
          <th>{t(langKeys.invoice_amount)}</th>
          <th>{t(langKeys.date)}</th>
          <th>{t(langKeys.status)}</th>
          <th>{t(langKeys.invoice)}</th>
        </tr>
      </Thead>
      <Tbody>
        {invoices.map((invoice) => (
          <tr key={invoice.id}>
            <td>
              <MText variant="subtitle" style={{ margin: 0 }}>
                {invoice.total
                  ? formatPrice(invoice.total / 100, invoice.currency_code)
                  : formatPrice(0, invoice.currency_code)}
              </MText>
            </td>
            <td>{formatUTCSecondsDate(invoice.date)}</td>
            <td>
              <MBanner
                fullWidth={false}
                size="small"
                type={getAlertType(invoice.status)}
                style={{
                  textTransform: "capitalize",
                }}
              >
                {translateInvoiceStatus(t, invoice.status)}
              </MBanner>
            </td>
            <td>
              <Buttons>
                <MButton
                  variant="minimal"
                  onClick={() => handleDownload(invoice.id)}
                  loading={invoice.id === downloadingID}
                >
                  {t(langKeys.download)}
                </MButton>

                {isUnpaid(invoice) && (
                  <MButton
                    variant="secondary"
                    onClick={() => onPayInvoice(invoice.id)}
                  >
                    {t(langKeys.invoice_pay_now)}
                  </MButton>
                )}

                <NextRetry invoice={invoice} />
              </Buttons>
            </td>
          </tr>
        ))}
      </Tbody>
    </Table>
  )
}

const Buttons = styled.div`
  display: flex;

  > *:not(:first-child) {
    margin-left: ${spacing.S};
  }
`
