import { useMemo, useState } from "react"

import { TUserBillingInfoBillingAddress } from "src/data/billing/types/billingTypes"
import { TShippingDestination } from "src/data/ecommerce/ecommerceTypes"

export function useBillingForm(
  billingAddress: TUserBillingInfoBillingAddress | undefined,
  vatNumber: string | undefined,
  shippingDestinations: TShippingDestination[]
) {
  const [formVatNumber, setFormVatNumber] = useState(vatNumber ?? "")
  const [formBillingAddress, setFormBillingAddress] =
    useState<TUserBillingInfoBillingAddress>(() =>
      initBillingAddress(billingAddress, shippingDestinations)
    )

  function setFormFields(fields: Partial<TUserBillingInfoBillingAddress>) {
    setFormBillingAddress((prevState) => ({ ...prevState, ...fields }))
  }

  const shippingDestination = useMemo(() => {
    return shippingDestinations.find(
      (dest) => dest.country_code === formBillingAddress.country
    )
  }, [shippingDestinations, formBillingAddress.country])

  return {
    billingAddress: formBillingAddress,
    vatNumber: formVatNumber,
    setFormFields,
    setFormVatNumber,
    shippingDestination,
  }
}

function initBillingAddress(
  billingAddress: TUserBillingInfoBillingAddress | undefined,
  shippingDestinations: TShippingDestination[]
): TUserBillingInfoBillingAddress {
  /**
   * If the billing address is already set, we use it as is, but only if there is
   * a matching shipping destination that is found.
   */
  if (billingAddress) {
    const match = shippingDestinations.find(
      (dest) => dest.country_code === billingAddress.country
    )

    if (match) {
      return billingAddress
    }
  }

  return {
    first_name: "",
    last_name: "",
    city: "",
    country: "US", // Defaulting to the US for now, should utilize geolocation for better guessing in the future
    line1: "",
    line2: "",
    state_code: "",
    email: "",
    phone: "",
    zip: "",
  }
}
