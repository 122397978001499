import { useState } from "react"
import styled from "styled-components"

import { mColors } from "@minuthq/meatball-ui-react"

import { ReplacePaymentMethod } from "src/components/Account/BillingPortal/PaymentMethod/ReplacePaymentMethod"
import {
  useFetchAvailablePlansForUser,
  useFetchPaymentMethod,
} from "src/data/billing/queries/billingQueries"
import { TUserBillingInfo } from "src/data/billing/types/billingTypes"
import { useFetchShippingDestinations } from "src/data/ecommerce/ecommerceQueries"
import { useFetchNonPaidInvoices } from "src/data/invoices/queries/InvoiceQueries"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import { TextButton } from "src/ui/Button/TextButton"
import { MBanner } from "src/ui/MBanner/MBanner"
import { spacing } from "src/ui/spacing"

import { AddPaymentMethod } from "./AddPaymentMethod"
import { CardImitation } from "./CardImitation"
import { PaymentMethodLoading } from "./PaymentMethodLoading"
import { RemoveCardDialog } from "./RemoveCardDialog"

export function PaymentMethod({
  userBillingInfo,
  userId,
}: {
  userBillingInfo: TUserBillingInfo
  userId: string
}) {
  const { t } = useTranslate()

  const [confirmDelete, setConfirmDelete] = useState(false)
  const [addPaymentMethod, setAddPaymentMethod] = useState(false)

  /**
   * Be careful adding react query fetches here, since the Stripe form is an
   * Iframe we will refech everytime we click out of that frame. The effects of
   * this will mean that the user cannot save the card details since the form
   * will alwasy reload when you exit it. This is mainly a problem when we
   * depend on the loading state of said query.
   */
  const fetchPaymentMethod = useFetchPaymentMethod({
    paymentSourceId: userBillingInfo.primary_payment_source_id,
    options: {
      enabled: !!userBillingInfo.primary_payment_source_id,
    },
  })

  // replace with current plan and also check if the user has no plan
  // Card should only be removable if:
  // * (User has no subscription OR subscription is cancelled) AND
  // * User has no unpaid invoices
  const fetchAvailablePlansForUser = useFetchAvailablePlansForUser({
    userId,
  })

  const fetchNonPaidInvoices = useFetchNonPaidInvoices()

  const fetchShippingDestinations = useFetchShippingDestinations()

  const isLoading =
    fetchPaymentMethod.isInitialLoading ||
    fetchAvailablePlansForUser.isInitialLoading ||
    fetchNonPaidInvoices.isInitialLoading ||
    fetchShippingDestinations.isLoading

  const isError =
    fetchPaymentMethod.isError ||
    fetchAvailablePlansForUser.isError ||
    fetchNonPaidInvoices.isError ||
    fetchShippingDestinations.isError

  const paymentMethod = fetchPaymentMethod.data
  const hasNoPlan = !fetchAvailablePlansForUser.data?.current_plan
  const planIsCancelled =
    fetchAvailablePlansForUser.data?.current_plan?.status === "cancelled"
  const hasUnpaidInvoices = (fetchNonPaidInvoices.data?.list.length ?? 0) > 0
  const cardIsRemovable = (hasNoPlan || planIsCancelled) && !hasUnpaidInvoices
  const replaceCard = !cardIsRemovable

  if (isLoading) {
    return <PaymentMethodLoading />
  }

  if (isError) {
    return (
      <MBanner type="error" fullWidth>
        {t(langKeys.failed_general_error_body)}
      </MBanner>
    )
  }

  if (!paymentMethod) {
    if (!addPaymentMethod) {
      return (
        <NoCardBox>
          <MButton onClick={() => setAddPaymentMethod(true)}>
            {t(langKeys.payment_method_add)}
          </MButton>
        </NoCardBox>
      )
    } else {
      return (
        <AddPaymentMethod
          userBillingInfo={userBillingInfo}
          shippingDestinations={fetchShippingDestinations.data}
        />
      )
    }
  }

  return (
    <div>
      <CardImitation paymentMethod={paymentMethod} />

      <div style={{ display: "flex", flexDirection: "column" }}>
        {replaceCard ? (
          <ReplacePaymentMethod
            userBillingInfo={userBillingInfo}
            shippingDestinations={fetchShippingDestinations.data}
          />
        ) : (
          <TextButton
            disabled={replaceCard}
            onClick={() => setConfirmDelete(true)}
            style={{ marginTop: spacing.S, alignSelf: "flex-end" }}
          >
            {t(langKeys.remove)}
          </TextButton>
        )}
      </div>

      <RemoveCardDialog
        paymentSourceId={paymentMethod.id}
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
      />
    </div>
  )
}

const NoCardBox = styled.div`
  padding: ${spacing.L};
  background: ${mColors.neutral};
  min-height: 130px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`
