import { ReactElement, useState } from "react"
import styled from "styled-components"

import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { StripeCardElement } from "@stripe/stripe-js"
import { AxiosError } from "axios"

import { BillingAddressForm } from "src/components/Account/BillingPortal/BillingAddress/BillingAddressForm"
import { transformBillingAddress } from "src/components/Account/BillingPortal/BillingAddress/saveAddress"
import { useHandleCardChange } from "src/components/Account/BillingPortal/PaymentMethod/useHandleCardChange"
import {
  usePostCreatePaymentSourceWithIntent,
  usePostSetupIntent,
  usePostUpdateBillingInfo,
  usePutReplacePrimaryPaymentSource,
} from "src/data/billing/queries/billingQueries"
import {
  TUserBillingInfo,
  TUserBillingInfoBillingAddress,
} from "src/data/billing/types/billingTypes"
import { TShippingDestination } from "src/data/ecommerce/ecommerceTypes"
import { mapChargebeeGatewayToStripeRegion } from "src/data/ecommerce/ecommerceUtils"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import { colorScale, mColors } from "src/ui/colors"
import AmericanExpressIcon from "src/ui/icons/american-express.svg"
import MasterCardIcon from "src/ui/icons/mastercard.svg"
import VisaIcon from "src/ui/icons/visa.svg"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { ErrorService } from "src/utils/ErrorService"

export function AddCardForm({
  userBillingInfo,
  billingAddress,
  vatNumber,
  setFormFields,
  setFormVatNumber,
  replace,
  chargebeeGateway,
  shippingDestinations,
}: {
  userBillingInfo: TUserBillingInfo
  billingAddress: TUserBillingInfoBillingAddress
  vatNumber: string
  setFormFields: (fields: Partial<TUserBillingInfoBillingAddress>) => void
  setFormVatNumber: (param: string) => void
  replace: boolean
  chargebeeGateway: string
  shippingDestinations: TShippingDestination[]
}) {
  const { t } = useTranslate()

  const { user_id } = useGetUser()
  const { handleCardChange, cardComplete, cardError } = useHandleCardChange()

  const [error, setError] = useState<ReactElement | string>("")
  const [submitting, setSubmitting] = useState(false)

  const postSetupIntent = usePostSetupIntent()
  const postCreatePaymentSourceWithIntent =
    usePostCreatePaymentSourceWithIntent()
  const putReplacePrimaryPaymentSource = usePutReplacePrimaryPaymentSource()
  const postUpdateBillingInfo = usePostUpdateBillingInfo()

  const stripe = useStripe()
  const elements = useElements()

  const formId = "add-payment-method-form"

  function handleSubmit(card: StripeCardElement | null | undefined) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
    return async (event: any) => {
      event.preventDefault()

      if (submitting || !cardComplete) {
        return
      }

      setError("")
      setSubmitting(true)

      try {
        if (!card) {
          throw Error("Something went wrong, card was undefined")
        }

        if (!userBillingInfo) {
          throw Error("Something went wrong, userBillingInfo was undefined")
        }

        await postUpdateBillingInfo.mutateAsync({
          customerId: user_id,
          body: {
            vat_number: vatNumber,
            billing_address: transformBillingAddress(billingAddress),
          },
          updateCache: false, // We don't want to update the cache, since that will cause issues with the stripe card element rerendering before it having a chance to confirm the information later on
        })

        await saveCard({
          card,
          billingAddress,
          customerId: user_id,
          chargebeeGateway,
        })
      } catch (error) {
        ErrorService.captureException(error)

        const err = error as AxiosError<{ error_msg: string }>

        if (err.response?.status === 400 && err.response?.data.error_msg) {
          setError(err.response.data.error_msg)
        } else if (err.message) {
          setError(err.message)
        } else {
          setError(<ErrorMessage />)
        }
      }

      setSubmitting(false)
    }
  }

  async function saveCard({
    card,
    billingAddress,
    customerId,
    chargebeeGateway,
  }: {
    card: StripeCardElement
    billingAddress: TUserBillingInfoBillingAddress
    customerId: string
    chargebeeGateway: string
  }) {
    const clientSecret = await getClientSecret(chargebeeGateway)
    const setupIntent = await confirmCard(card, billingAddress, clientSecret)
    const createNewPayment = replace
      ? putReplacePrimaryPaymentSource
      : postCreatePaymentSourceWithIntent

    return await createNewPayment.mutateAsync({
      body: {
        customer_id: customerId,
        payment_intent: {
          gateway_account_id: chargebeeGateway,
          gw_token: setupIntent.id,
        },
      },
    })
  }

  async function getClientSecret(chargebeeGateway: string): Promise<string> {
    // GT-1869: Update to use gateway parameter and remove mapping function when api is ready
    const response = await postSetupIntent.mutateAsync({
      stripe_account_region:
        mapChargebeeGatewayToStripeRegion(chargebeeGateway),
    })

    const { client_secret } = response

    if (!client_secret) {
      throw Error("Something went wrong, couldn't setup card")
    }

    return client_secret
  }

  async function confirmCard(
    card: StripeCardElement,
    billingAddress: TUserBillingInfoBillingAddress,
    clientSecret: string
  ) {
    if (!stripe) {
      throw Error("Something went wrong, payment processor is not initialized")
    }

    const paymentMethod = {
      card,
      billing_details: {
        address: {
          postal_code: billingAddress?.zip,
          country: billingAddress?.country,
          line1: billingAddress?.line1,
          line2: billingAddress?.line2,
        },
        name: `${billingAddress?.first_name} ${billingAddress?.last_name}`,
        phone: billingAddress?.phone,
      },
    }

    const { setupIntent, error } = await stripe.confirmCardSetup(clientSecret, {
      payment_method: paymentMethod,
    })

    if (error || !setupIntent) {
      throw Error(error?.message ?? t(langKeys.failed_to_process_card))
    }

    return setupIntent
  }

  if (!stripe || !elements) {
    return null
  }

  return (
    <div>
      <Form
        id={formId}
        onSubmit={handleSubmit(elements?.getElement(CardElement))}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <MText variant="subtitle">{t(langKeys.credit_card)}</MText>
          <PaymentOptions />
        </div>

        <CardBox $complete={cardComplete}>
          <CardElement options={CARD_OPTIONS} onChange={handleCardChange} />
        </CardBox>

        {cardError && (
          <div style={{ color: mColors.systemErrorDark }}>{cardError}</div>
        )}

        <BillingAddressForm
          billingAddress={billingAddress}
          vatNumber={vatNumber}
          setFormFields={setFormFields}
          setFormVatNumber={setFormVatNumber}
          shippingDestinations={shippingDestinations}
        />

        {error && (
          <MBanner type="error" fullWidth>
            {error}
          </MBanner>
        )}
      </Form>

      <MButton
        form={formId}
        type="submit"
        loading={submitting}
        disabled={submitting || !cardComplete}
        style={{ width: "100%", marginTop: spacing.S }}
      >
        {t(langKeys.save)}
      </MButton>
    </div>
  )
}

function ErrorMessage() {
  const { t } = useTranslate()

  return (
    <>
      {t(langKeys.failed_to_process_card)}. {t(langKeys.failed_contact_support)}
    </>
  )
}

const CARD_OPTIONS = {
  style: {
    base: {
      color: mColors.textPrimary,
      "::placeholder": {
        color: mColors.textTertiary,
      },
    },
    invalid: {
      color: "#9e2146",
    },
  },
  hidePostalCode: true,
}

function PaymentOptions() {
  return (
    <PaymentOptionsBox>
      <VisaIcon />
      <MasterCardIcon />
      <AmericanExpressIcon />
    </PaymentOptionsBox>
  )
}

const PaymentOptionsBox = styled.div`
  display: flex;

  > * {
    margin-left: ${spacing.XS};
  }

  > svg {
    width: 33px;
    height: 28px;
  }
`

interface ICardBox {
  readonly $complete: boolean
}

const Form = styled.form`
  display: grid;
  gap: ${spacing.M};
`

const CardBox = styled.div<ICardBox>`
  .StripeElement {
    display: block;
    padding: ${spacing.M};
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-color: ${(props: ICardBox) =>
      props.$complete ? colorScale.good[200] : mColors.neutral};
    outline: 0;
    border-radius: 8px;
    background: ${(props: ICardBox) =>
      props.$complete ? colorScale.good[100] : mColors.neutral};
    color: red;
    font-size: 0.875rem;
  }

  .StripeElement--focus {
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }
`
