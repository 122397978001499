import { useState } from "react"
import styled from "styled-components"

import { AxiosError, isAxiosError } from "axios"
import { useSnackbar } from "notistack"

import { DesktopNotificationsToggle } from "src/components/Notifications/DesktopNotificationsToggle"
import { EditField } from "src/components/Settings/EditField"
import { SettingText } from "src/components/Settings/Setting/SettingText"
import { isDemoEnv } from "src/constants/env"
import { changePasswordUrl } from "src/constants/hrefs"
import {
  ClockType,
  EmailNotifications,
  IProfileSettingPayload,
  Language,
  Temperature,
  UserPhone,
} from "src/data/profileSettings/profileSettings"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useDeleteUser, usePutUser } from "src/data/user/queries/userQueries"
import { TUser } from "src/data/user/user"
import { useFlags } from "src/hooks/useFlags"
import { useLogout } from "src/hooks/useLogout"
import { useTranslate } from "src/i18n/useTranslate"
import { DocumentHead } from "src/router/DocumentHead"
import { MButton } from "src/ui/Button/MButton"
import { TextButton } from "src/ui/Button/TextButton"
import { mColors } from "src/ui/colors"
import ConfirmDialog from "src/ui/Dialog/ConfirmDialog"
import { DefaultBox } from "src/ui/Layout/DefaultBox"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

const notificationConfigs = [EmailNotifications]

export function General() {
  const { t, langKeys, i18n } = useTranslate()
  const putUser = usePutUser()
  const user: TUser = useGetUser()
  const { enqueueSnackbar } = useSnackbar()
  const deleteUser = useDeleteUser()
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
  const { logout } = useLogout()
  const { beta } = useFlags()
  const regionConfigs = [Language, ClockType, Temperature]

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
  async function save(payload: any, editField: any) {
    await putUser.mutateAsync(
      { userId: user.user_id, userData: payload },
      {
        onError(e) {
          const err = e as AxiosError
          const defaultError = t(langKeys.failed_contact_support)
          const errorMsg =
            editField?.onError(err.response?.status) || defaultError
          enqueueSnackbar(errorMsg, {
            variant: "error",
          })
        },
        onSuccess() {
          if (editField.id === Language.id) {
            if (!!payload.locale) {
              // i18next requires languages to use dashes
              const i18nextLng = payload.locale.replace("_", "-")
              i18n.changeLanguage(i18nextLng)
            }
          }
        },
      }
    )
  }
  // TODO WEB-898: Adjust saveAccountInfo to replace save fully when removing Editfield
  async function saveAccountInfo(payload: IProfileSettingPayload) {
    try {
      await putUser.mutateAsync({ userId: user.user_id, userData: payload })
      return { isSuccess: true }
    } catch (error) {
      const defaultError = t(langKeys.failed_contact_support)

      if (!isAxiosError(error) || !error.response) {
        return { isSuccess: false, message: defaultError }
      }

      const errorKey = error.response.data.error_key
      const status = error.response.status

      const errorMessages: Record<number, string> = {
        400:
          errorKey === "invalid_email"
            ? t(langKeys.update_user_account_email_invalid)
            : t(langKeys.update_user_account_invalid_format),
        409: t(langKeys.update_user_account_email_exists),
      }

      return { isSuccess: false, message: errorMessages[status] }
    }
  }

  async function handleDelete() {
    deleteUser.mutate(
      { userId: user.user_id },
      {
        onSuccess: () =>
          logout({
            reason: "User deleted their account",
            userInitiated: true,
          }),
      }
    )
  }

  return (
    <DefaultBox size="small">
      <DocumentHead title={t(langKeys.account)} />

      <Section>
        <MText variant="heading2" marginBottom={spacing.L}>
          {t(langKeys.account)}
        </MText>
        <SettingItemWrapper>
          <SettingText
            title={t(langKeys.name)}
            value={user.fullname}
            onSave={(val) => saveAccountInfo({ fullname: val })}
            textFieldProps={{
              type: "text",
            }}
          />
          <SettingText
            title={t(langKeys.email)}
            value={user.email}
            onSave={(val) => saveAccountInfo({ email: val })}
            textFieldProps={{
              type: "email",
            }}
          />
        </SettingItemWrapper>
        <EditField
          fieldData={UserPhone}
          submit={(val, c) => save(c.payload(val, user), c)}
          loading={putUser.isLoading}
          storedSettings={[user]}
        />

        <SectionItemWrapper>
          <SettingTitleBox>
            <MText variant="subtitle" marginBottom={spacing.S}>
              {t(langKeys.password)}
            </MText>
            <TextButton
              onClick={() => window.open(changePasswordUrl(), "_blank")}
            >
              {t(langKeys.change_password)}
            </TextButton>
          </SettingTitleBox>
          ••••••••••••
        </SectionItemWrapper>
      </Section>

      <Section>
        <MText variant="heading2">
          {t(langKeys.notification, { count: 2 })}
        </MText>
        {notificationConfigs.map((c, index) => (
          <EditField
            key={index}
            fieldData={c}
            submit={(val, c) => save(c.payload(val, user), c)}
            loading={putUser.isLoading}
            storedSettings={[user]}
          />
        ))}

        <SectionItemWrapper>
          <DesktopNotificationsToggle />
        </SectionItemWrapper>
      </Section>

      <Section>
        <MText variant="heading2">{t(langKeys.settings_language_region)}</MText>
        {regionConfigs.map((c, index) => (
          <EditField
            key={index}
            fieldData={c}
            submit={(val, c) => save(c.payload(val, user), c)}
            loading={putUser.isLoading}
            storedSettings={[user]}
          />
        ))}
      </Section>

      <Section hidden={!beta || isDemoEnv}>
        <MText variant="heading2">{t(langKeys.delete_account)}</MText>
        <SectionBody>
          <MText variant="bodyS">{t(langKeys.delete_account_body)}</MText>
        </SectionBody>
        <MButton
          color="destructive"
          variant="secondary"
          onClick={() => setShowDeleteConfirm(true)}
        >
          Delete account
        </MButton>
      </Section>

      <ConfirmDialog
        title={t(langKeys.delete_account_title)}
        description={t(langKeys.delete_account_body)}
        open={showDeleteConfirm}
        onClose={() => {
          setShowDeleteConfirm(false)
          deleteUser.reset() // remove error state on close
        }}
        onConfirm={handleDelete}
        confirmButtonProps={{
          color: "destructive",
          loading: deleteUser.isLoading,
        }}
        confirmLabel={t(langKeys.delete_account)}
        error={!!deleteUser.error && t(langKeys.failed_general_error_title)}
      />
    </DefaultBox>
  )
}

const Section = styled.section`
  margin-bottom: ${spacing.XL};
`

// TODO WEB-898: Use if you need to add margin between section items and one of
// them is an Editfield 😒
const SectionItemWrapper = styled.div`
  margin-top: ${spacing.XL};
`

const SectionBody = styled.div`
  margin: ${spacing.XS} 0 ${spacing.L};
`

const SettingTitleBox = styled.div`
  display: flex;
  justify-content: space-between;
`

const SettingItemWrapper = styled.div`
  & > * {
    border-bottom: 1px solid ${mColors.divider};
    padding-bottom: ${spacing.L};
    margin-top: ${spacing.XL};
  }
`
