import { useState } from "react"
import styled from "styled-components"

import { formatUTCSecondsDate } from "src/components/Account/BillingPortal/ChangePlan/utils"
import {
  getAlertType,
  isUnpaid,
  translateInvoiceStatus,
} from "src/components/Account/BillingPortal/Payments/paymentsUtil"
import { IInvoice } from "src/components/Account/types"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import { backgroundGrayV2 } from "src/ui/colors"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { formatPrice } from "src/utils/formatPrice"

import { NextRetry } from "./Payments"

export function MobileTable({
  invoices,
  onPayInvoice,
  getPDF,
}: {
  invoices: IInvoice[]
  onPayInvoice: (id: string) => void
  getPDF: (id: string) => Promise<void>
}) {
  const { t, langKeys } = useTranslate()
  const [downloadingID, setDownloadingID] = useState<string | null>()

  async function handleDownload(invoiceID: string) {
    setDownloadingID(invoiceID)
    await getPDF(invoiceID)
    setDownloadingID(null)
  }

  return (
    <>
      {invoices.map((invoice) => {
        return (
          <Invoice key={invoice.id}>
            <Details>
              <div>
                <MText variant="heading2">
                  {invoice.total
                    ? formatPrice(invoice.total / 100, invoice.currency_code)
                    : formatPrice(0, invoice.currency_code)}
                </MText>

                <div style={{ display: "flex" }}>
                  <MText variant="bodyS" color="secondary">
                    {formatUTCSecondsDate(invoice.date)}
                  </MText>
                  <MBanner
                    fullWidth={false}
                    size="small"
                    type={getAlertType(invoice.status)}
                    style={{
                      textTransform: "capitalize",
                      marginLeft: spacing.XS,
                    }}
                  >
                    {translateInvoiceStatus(t, invoice.status)}
                  </MBanner>
                </div>
                <NextRetry
                  invoice={invoice}
                  style={{ marginTop: spacing.XS }}
                />
              </div>

              <Buttons>
                {isUnpaid(invoice) && (
                  <MButton
                    variant="secondary"
                    onClick={() => onPayInvoice(invoice.id)}
                  >
                    {t(langKeys.invoice_pay_now)}
                  </MButton>
                )}
                <MButton
                  variant="minimal"
                  onClick={() => handleDownload(invoice.id)}
                  loading={invoice.id === downloadingID}
                >
                  {t(langKeys.download)}
                </MButton>
              </Buttons>
            </Details>
          </Invoice>
        )
      })}
    </>
  )
}

const Invoice = styled.div`
  margin: ${spacing.S} 0;
  padding: ${spacing.S} 0;

  &:not(:last-child) {
    border-bottom: 1px solid ${backgroundGrayV2};
  }
`

const Details = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  > *:not(:first-child) {
    margin-left: ${spacing.S};
  }
`

const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  flex: 1;

  > *:not(:first-child) {
    margin-left: ${spacing.S};
  }
`
