import { useMemo, useState } from "react"
import styled from "styled-components"

import {
  getProvinceArray,
  isEuropeanUnionMemberState,
} from "src/components/Account/BillingPortal/countryData"
import { TUserBillingInfoBillingAddress } from "src/data/billing/types/billingTypes"
import { getCountryDisplayName } from "src/data/countries/countryUtil"
import {
  TCountryCode,
  TShippingDestination,
} from "src/data/ecommerce/ecommerceTypes"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { AddButton } from "src/ui/Button/AddButton"
import { MSelect, TMSelectOptions } from "src/ui/MSelect/MSelect"
import { MTextField } from "src/ui/MTextField/MTextField"
import { spacing } from "src/ui/spacing"

export function BillingAddressForm({
  billingAddress,
  vatNumber,
  setFormFields,
  setFormVatNumber,
  shippingDestinations,
}: {
  vatNumber: string | undefined
  billingAddress: TUserBillingInfoBillingAddress
  setFormFields: (param: Partial<TUserBillingInfoBillingAddress>) => void
  setFormVatNumber: (param: string) => void
  shippingDestinations: TShippingDestination[]
}) {
  const { t } = useTranslate()
  const user = useGetUser()

  const [showVatField, setShowVatField] = useState(
    vatNumber && vatNumber.length > 0
  )
  const provinces = getProvinceArray(billingAddress.country)

  function printOptional() {
    return `(${t(langKeys.input_optional)?.toLocaleLowerCase()})`
  }

  const billingCountries = useMemo(() => {
    return getBillingCountryOptions({
      shippingDestinations,
      locale: user.preferred_language,
    })
  }, [shippingDestinations, user.preferred_language])

  return (
    <Wrapper>
      <Row>
        <MTextField
          label={t(langKeys.first_name)}
          required
          autoComplete="given-name"
          value={valueWrapper(billingAddress.first_name)}
          onChange={(value) => setFormFields({ first_name: value })}
        />
        <MTextField
          label={t(langKeys.last_name)}
          autoComplete="family-name"
          required
          value={valueWrapper(billingAddress.last_name)}
          onChange={(value) => setFormFields({ last_name: value })}
        />
      </Row>

      <MTextField
        label={`${t(langKeys.address_company)} ${printOptional()}`}
        autoComplete="organization"
        value={valueWrapper(billingAddress.company)}
        onChange={(value) => setFormFields({ company: value })}
      />

      <MTextField
        label={t(langKeys.email)}
        type="email"
        autoComplete="email"
        required
        value={valueWrapper(billingAddress.email)}
        onChange={(value) => setFormFields({ email: value })}
      />

      <MTextField
        label={`${t(langKeys.phone)} ${printOptional()}`}
        // Should be phone
        type="text"
        autoComplete="tel"
        value={valueWrapper(billingAddress.phone)}
        onChange={(value) => setFormFields({ phone: value })}
      />

      <MTextField
        label={t(langKeys.address_form_street_name_1)}
        autoComplete="address-line-1"
        required
        value={valueWrapper(billingAddress.line1)}
        onChange={(value) => setFormFields({ line1: value })}
      />

      <MTextField
        label={`${t(langKeys.address_form_street_name_2)}`}
        autoComplete="address-line-2"
        value={valueWrapper(billingAddress.line2)}
        onChange={(value) => setFormFields({ line2: value })}
      />

      <Row>
        <MTextField
          label={`${t(langKeys.address_form_city)}`}
          autoComplete="address-level2"
          required
          value={valueWrapper(billingAddress.city)}
          onChange={(value) => setFormFields({ city: value })}
        />
        <MTextField
          label={t(langKeys.address_form_postcode)}
          autoComplete="postal-code"
          required
          value={valueWrapper(billingAddress.zip)}
          onChange={(value) => setFormFields({ zip: value })}
        />
      </Row>

      <Row>
        <MSelect
          label={t(langKeys.address_form_country)}
          value={valueWrapper(billingAddress.country)}
          options={[
            { value: "", label: "", hidden: true },
            ...billingCountries,
          ]}
          onChange={(selectedValue) => {
            setFormFields({
              country: selectedValue as TCountryCode,
            })
          }}
          autoComplete="country"
          required
        />

        {provinces.length > 0 && (
          <>
            <MSelect
              label={t(langKeys.address_form_state)}
              value={valueWrapper(billingAddress.state_code)}
              options={[
                {
                  value: "",
                  label: "",
                  hidden: true,
                },
                ...provinces.map((province) => ({
                  key: province.code,
                  label: province.label,
                  value: province.code,
                })),
              ]}
              onChange={(selectedValue) => {
                setFormFields({
                  state_code: selectedValue,
                })
              }}
              autoComplete="address-level1"
              required
            />
          </>
        )}
      </Row>

      {isVatExempt(billingAddress.country) &&
        (showVatField ? (
          <Row>
            <MTextField
              autoComplete="organization-vat"
              placeholder=""
              value={valueWrapper(vatNumber)}
              startAdornment={<b>{valueWrapper(billingAddress.country)}</b>}
              onChange={(value) => setFormVatNumber(value)}
            />
          </Row>
        ) : (
          <AddButton
            onClick={() => setShowVatField(true)}
            style={{ width: "fit-content" }}
          >
            {t(langKeys.billing_add_vat_number)}
          </AddButton>
        ))}
    </Wrapper>
  )
}

function valueWrapper(value?: string) {
  if (!value) {
    return ""
  }

  return value
}

function isVatExempt(countryCode: string | undefined): boolean {
  const _countryCode = countryCode?.toUpperCase()
  return isEuropeanUnionMemberState(_countryCode) && _countryCode !== "SE"
}

function getBillingCountryOptions({
  shippingDestinations,
  locale,
}: {
  shippingDestinations: TShippingDestination[]
  locale: string
}): TMSelectOptions<string> {
  const countries = shippingDestinations.map((dest) => ({
    key: dest.country_code,
    label: getCountryDisplayName({
      countryCode: dest.country_code,
      locale,
    }),
    value: dest.country_code,
  }))

  return countries.sort((a, b) => {
    return a.label.localeCompare(b.label)
  })
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`

const Row = styled.div`
  display: flex;

  > * {
    flex: 1;
    margin-left: ${spacing.XS2};
    margin-right: ${spacing.XS2};
  }

  *:first-child {
    margin-left: 0;
  }

  *:last-child {
    margin-right: 0;
  }
`
