import { useMemo } from "react"

import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"

import { useBillingForm } from "src/components/Account/BillingPortal/BillingAddress/useBillingForm"
import { AddCardForm } from "src/components/Account/BillingPortal/PaymentMethod/AddCardForm"
import { TUserBillingInfo } from "src/data/billing/types/billingTypes"
import { TShippingDestination } from "src/data/ecommerce/ecommerceTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { MBanner } from "src/ui/MBanner/MBanner"

export function AddPaymentMethod({
  userBillingInfo,
  shippingDestinations,
}: {
  userBillingInfo: TUserBillingInfo
  shippingDestinations: TShippingDestination[]
}) {
  const { t, langKeys } = useTranslate()

  const {
    billingAddress,
    vatNumber,
    setFormFields,
    setFormVatNumber,
    shippingDestination,
  } = useBillingForm(
    userBillingInfo.billing_address,
    userBillingInfo.vat_number,
    shippingDestinations
  )

  const { stripe_api_gateway, chargebee_api_gateway } =
    shippingDestination?.payment_gateway ?? {}

  const stripeSdkPromise = useMemo(() => {
    if (!stripe_api_gateway) {
      return null
    }

    return loadStripe(stripe_api_gateway)
  }, [stripe_api_gateway])

  if (!chargebee_api_gateway || !stripe_api_gateway) {
    return (
      <MBanner type="error">{t(langKeys.failed_general_error_body)}</MBanner>
    )
  }

  return (
    <Elements stripe={stripeSdkPromise} key={stripe_api_gateway}>
      <AddCardForm
        userBillingInfo={userBillingInfo}
        billingAddress={billingAddress}
        vatNumber={vatNumber}
        setFormFields={setFormFields}
        setFormVatNumber={setFormVatNumber}
        replace={false}
        chargebeeGateway={chargebee_api_gateway}
        shippingDestinations={shippingDestinations}
      />
    </Elements>
  )
}
