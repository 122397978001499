export type TStripeRegion = "se" | "us" | "uk"

export function mapChargebeeGatewayToStripeRegion(
  chargebeeGateway: string
): TStripeRegion {
  switch (chargebeeGateway) {
    case import.meta.env.REACT_APP_CHARGEBEE_GW_ACCOUNT_SE: {
      return "se"
    }
    case import.meta.env.REACT_APP_CHARGEBEE_GW_ACCOUNT_US: {
      return "us"
    }
    case import.meta.env.REACT_APP_CHARGEBEE_GW_ACCOUNT_UK: {
      return "uk"
    }
    default: {
      throw new Error(`Unrecognized chargebee gateway: ${chargebeeGateway}`)
    }
  }
}
