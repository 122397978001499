import { IInvoice, InvoiceStatusType } from "src/components/Account/types"
import { ITranslateFunction } from "src/i18n/i18nTypes"
import { langKeys } from "src/i18n/langKeys"

export function isUnpaid(invoice: IInvoice) {
  return (
    invoice.status === InvoiceStatusType.NOT_PAID ||
    invoice.status === InvoiceStatusType.PAYMENT_DUE
  )
}

export function getAlertType(status: InvoiceStatusType) {
  if (status === InvoiceStatusType.PAID) {
    return "good"
  }

  if (status === InvoiceStatusType.PAYMENT_DUE) {
    return "warning"
  }

  return "error"
}

export function translateInvoiceStatus(
  t: ITranslateFunction,
  status: InvoiceStatusType
) {
  const statusTranslations = {
    [InvoiceStatusType.PAYMENT_DUE]: t(langKeys.invoice_status_payment_due),
    [InvoiceStatusType.NOT_PAID]: t(langKeys.invoice_status_not_paid),
    [InvoiceStatusType.VOIDED]: t(langKeys.invoice_status_voided),
    [InvoiceStatusType.PENDING]: t(langKeys.invoice_status_pending),
    [InvoiceStatusType.PAID]: t(langKeys.invoice_status_paid),
    [InvoiceStatusType.POSTED]: t(langKeys.invoice_status_posted),
  }[status]

  return statusTranslations
}
