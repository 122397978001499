import { useState } from "react"
import styled from "styled-components"

import { useFetchBillingInfoWithCreate } from "src/data/billing/queries/billingQueries"
import { useFetchShippingDestinations } from "src/data/ecommerce/ecommerceQueries"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import { TextButton } from "src/ui/Button/TextButton"
import { backgroundGrayV2 } from "src/ui/colors"
import { spacing } from "src/ui/spacing"

import { BillingAddressLoading } from "./BillingAddressLoading"
import { EditBillingDialog } from "./EditBillingDialog"
import { PrintedAddress } from "./PrintedAddress"

export function BillingAddress() {
  const { t } = useTranslate()
  const [showDialog, setShowDialog] = useState(false)
  const user = useGetUser()
  const fetchBillingInfoWithCreate = useFetchBillingInfoWithCreate({ user })
  const fetchShippingDestinations = useFetchShippingDestinations()

  if (
    fetchBillingInfoWithCreate.isLoading ||
    fetchShippingDestinations.isLoading
  ) {
    return <BillingAddressLoading />
  }

  const userBillingInfo = fetchBillingInfoWithCreate.data

  const shippingDestinations = fetchShippingDestinations.data

  if (!userBillingInfo || !shippingDestinations) {
    return null
  }

  const billingAddress = userBillingInfo.billing_address

  return (
    <>
      {billingAddress ? (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <PrintedAddress
            billingAddress={billingAddress}
            vatNumber={userBillingInfo.vat_number}
          />
          <TextButton
            onClick={() => setShowDialog(true)}
            style={{
              alignSelf: "flex-end",
              width: "fit-content",
              marginTop: spacing.S,
            }}
          >
            {t(langKeys.edit)}
          </TextButton>
        </div>
      ) : (
        <NoAddressBox>
          <MButton onClick={() => setShowDialog(true)}>
            {t(langKeys.edit)}
          </MButton>
        </NoAddressBox>
      )}

      {showDialog && (
        <EditBillingDialog
          open={showDialog}
          billingAddress={billingAddress}
          vatNumber={userBillingInfo.vat_number}
          customerId={user.user_id}
          onClose={() => setShowDialog(false)}
          shippingDestinations={shippingDestinations}
        />
      )}
    </>
  )
}

const NoAddressBox = styled.div`
  padding: ${spacing.L};
  background: ${backgroundGrayV2};
  min-height: 130px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`
